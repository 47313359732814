











import {
  SurveySessionsMounted,
  SurveySessionsUpdateScope,
} from '@/+state/survey-sessions/events';
import {
  surveySessionsInScope,
  surveySessionsIsLoading,
  surveySessionsScope,
} from '@/+state/survey-sessions/projections';
import BlueCard from '@/shared/components/BlueCard.vue';
import EmptyState from '@/shared/components/EmptyState.vue';
import { emit, select } from '@conversa/reflex';
import { defineComponent, onMounted } from '@vue/composition-api';
import SurveySessionsTable from './SurveySessionsTable.vue';

export default defineComponent({
  components: { SurveySessionsTable, EmptyState, BlueCard },
  setup() {
    onMounted(() => emit(SurveySessionsMounted()));
    return {
      loading: select(surveySessionsIsLoading),
      data: select(surveySessionsInScope),
      viewHistoric: select(surveySessionsScope),
      updateScope: scope => emit(SurveySessionsUpdateScope(scope)),
    };
  },
});
