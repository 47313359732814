



































import {
  SurveySessionsUpdateScope,
  SurveySessionPageUpdated,
} from '@/+state/survey-sessions/events';
import { surveySessionsCount } from '@/+state/survey-sessions/projections';
import { surveySessionsStore } from '@/+state/survey-sessions/store';
import { TABLE_CONFIG } from '@/constants/tableConfig';
import { SurveySessionsScope } from '@/models/survey-sessions';
import EmptyState from '@/shared/components/EmptyState.vue';
import { emit, select } from '@conversa/reflex';
import { computed, defineComponent } from '@vue/composition-api';

interface Data {
  date: string;
  modules: string;
}

export default defineComponent<{
  data: Data[];
  loading: boolean;
  viewHistoric: SurveySessionsScope;
}>({
  components: { EmptyState },
  props: ['data', 'loading', 'viewHistoric'],
  emits: ['view-historic-clicked'],
  setup(props) {
    const store = surveySessionsStore.inject();

    return {
      page: store.page,
      pageCount: select(surveySessionsCount),
      headers: TABLE_CONFIG.surveySessions.headers,
      tableRows: TABLE_CONFIG.surveySessions.tableRows,
      viewHistoricClicked: scope => {
        emit(SurveySessionsUpdateScope({ scope }));
      },
      updatePage: (page: number) => emit(SurveySessionPageUpdated({ page })),
      upcomingHistoric: computed(
        () =>
          `The patient does not have any ${
            props.viewHistoric === 'past' ? 'historic' : 'upcoming'
          } chats to display.`,
      ),
      historicScope: props.viewHistoric,
    };
  },
});
